import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';
import PlanListTable from '../PlanListTable';

ContainerMessageTable.propTypes = {
	updateSelectedPlans: PropTypes.func,
	message: PropTypes.object,
	parentState: PropTypes.shape({
		isLoading: PropTypes.bool,
		isSingleSelection: PropTypes.bool,
		savingsCardIsChecked: PropTypes.bool,
		selectionMessage: PropTypes.string,
		exceedMaxColumns: PropTypes.bool,
		maxDisplayColumns: PropTypes.number,
		isImprovedAccessStatic: PropTypes.bool,
		selectableOptions: PropTypes.shape({
			canSelectFormulary: PropTypes.bool,
			canSelectRestrictions: PropTypes.bool,
			canSelectSpecialtyPharmacy: PropTypes.bool,
			canSelectSavingsCard: PropTypes.bool,
		}),
		isSingleOptionSelectionEnabled: PropTypes.bool,
		isSingleOptionFormularyDrillDownEnabled: PropTypes.bool,
		isSingleOptionRestrictionDetailsEnabled: PropTypes.bool,
		isSingleOptionSpecialtyPharmacyEnabled: PropTypes.bool,
		isSingleOptionSavingsCardEnabled: PropTypes.bool,
		selectedPlans: PropTypes.array,
	}),
	setSelectableOptions: PropTypes.func,
	triggerToastAlert: PropTypes.func,
	executePrintOptionRestrictions: PropTypes.func,
	showTooManyOptionsSelectedMessage: PropTypes.func,
};

function ContainerMessageTable({
	updateSelectedPlans,
	message,
	parentState,
	setSelectableOptions,
	triggerToastAlert,
	executePrintOptionRestrictions,
	showTooManyOptionsSelectedMessage,
}) {
	const [open, setOpen] = useState(false);
	return (
		<>
			{/* <! -- Collapse row begins --> */}
			<div className='row my-4 mb-lg-3'>
				<div className='col-12 '>
					<button
						className='card pd-card px-4 py-4 text-start w-100'
						onClick={() => setOpen(!open)}
						data-bs-toggle='collapse'
						data-bs-target={`#message_container_${message.prescriberMessage.id}`}
						aria-expanded='false'
						aria-controls={`#message_container_${message.prescriberMessage.id}`}
					>
						<div className='d-flex justify-content-between align-items-center'>
							<h1
								className='type-24 type-bold text-primary my-0 py-0'
								dangerouslySetInnerHTML={{
									__html: message.prescriberMessage.mainMessage,
								}}
							></h1>
							<div className='btn btn-ghost-secondary btn-icon-only ms-4'>
								<span className={`fa-regular ${open ? 'fa-chevron-down' : 'fa-chevron-right'} btn-icon justify-content-end`}></span>
							</div>
						</div>
					</button>
				</div>
			</div>
			<Collapse in={open}>
				<div id={`message_container_${message.prescriberMessage.id}`} className='collapse'>
					<PlanListTable
						updateSelectedPlans={updateSelectedPlans}
						message={message}
						parentState={parentState}
						setSelectableOptions={setSelectableOptions}
						triggerToastAlert={triggerToastAlert}
						executePrintOptionRestrictions={executePrintOptionRestrictions}
						showTooManyOptionsSelectedMessage={showTooManyOptionsSelectedMessage}
					></PlanListTable>
				</div>
			</Collapse>
		</>
	);
}

export default ContainerMessageTable;
