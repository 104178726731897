export async function getContainerMessage(messageCode, prescriberId, productId, messageTypeId, excludePrintRestrictedPlans = false) {
	if (!messageCode || !prescriberId || !productId || (!messageTypeId && messageTypeId !== 0)) {
		throw new Error('Missing required parameters');
	}

	const url = `${process.env.REACT_APP_API_URL}Prescriber/ContainerMessage/${encodeURIComponent(messageCode)}/${encodeURIComponent(
		prescriberId
	)}/${encodeURIComponent(productId)}/${encodeURIComponent(messageTypeId)}?excludePrintRestrictedPlans=${encodeURIComponent(excludePrintRestrictedPlans)}`;
	const response = await fetch(url, {
		method: 'GET',
		credentials: 'include',
	});

	if (response.status === 200) {
		return await response.json();
	} else {
		throw response.statusText;
	}
}
