import { useState, useContext, useEffect } from 'react';
import useComponentVisible from '../hooks/useComponentVisible';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';
import PropType from 'prop-types';
import PlanListModal from "../components/modals/PlanListModal";
import { getBatchPrintPrescriber } from '../API/batchPrint';

BatchPrintRowOptionsMenu.propTypes = {
    onOptionsClick: PropType.func,
    item: PropType.object
};

function BatchPrintRowOptionsMenu({
    onOptionsClick,
    item
}) {
    const [appState] = useContext(AppContext);
    const [optionsMenuRef, optionsMenuIsVisible, setOptionsMenuIsVisible] = useComponentVisible(false);
    const [showPlanListModal, setShowPlanListModal] = useState(false);
    const [prescriberData, setPrescriberData] = useState();
    const [displayName, setDisplayName] = useState('');
    const [includeTargetName, setIncludeTargetName] = useState();

    useEffect(() => setIncludeTargetName(item.includeTargetName ?? false), [item.includeTargetName]);

    function handleTargetNameChecked(event) {
        //only set the state if the onOptionsClick function returns true
        if (onOptionsClick?.(item, event)) {
            setIncludeTargetName(event.target.checked);
        } else {
            event.preventDefault();
        }
    }

    async function getPrescriberData(item) {
        const prescriberData = await getBatchPrintPrescriber(item.type, appState.messageCode, item.id, appState?.currentProduct?.clientProductId, appState?.category?.messageTypeId, appState?.territory?.id);
        setDisplayName(item.displayName);
        setPrescriberData(prescriberData);
        setShowPlanListModal(true);
    }

    return (
        <div className={`row pd-plan-table-row mb-6 mb-lg-0 pt-3 pt-lg-0 justify-content-lg-between align-items-lg-center`}>
            <div ref={optionsMenuRef} className='pd-plan-col pd-plan-button pd-plan-button-options col-12 col-lg-1 mt-4 mb-2 my-lg-0'>
                {/* <!-- Options buttons and check boxes --> */}

                <div className='pd-plan-col pd-plan-options col-12 mb-3' id='options-row-1' style={{ display: optionsMenuIsVisible ? 'block' : 'none' }}>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-10 py-3'>
                            <h3 className='type-16 type-bold'>{getConfigValue(appState.config?.BatchPrint?.Page?.Provider?.Header, appState)?.Value}</h3>
                            <div className='d-flex justify-content-between align-items-center mb-5'>
                                <div>
                                    <div className="form-check mb-3 mb-md-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={includeTargetName}
                                            onChange={handleTargetNameChecked}
                                            id={`planlist-input-options-formularies-row${item.data.id}`}
                                        >
                                        </input>
                                        <label className="form-check-label type-14" htmlFor={`planlist-input-options-formularies-row${item.data.id}`}>{getConfigValue(appState.config?.BatchPrint?.Page?.IncludeTarget?.Label, appState)?.Value}</label>
                                    </div>
                                    <div className="mt-3">
                                        <button id={`provider-name-${item.data.id}`}
                                            className='dropdown-item cursor type-14 btn btn-ghost-primary btn-sm form-label'
                                            data-bs-toggle="modal" data-bs-target="#selected_target_plans"
                                            onClick={() => getPrescriberData(item.data)}>
                                            {getConfigValue(appState.config?.BatchPrint?.Page?.ViewPlans?.Label, appState)?.Value}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Button - options --> */}
                <div className='row justify-content-center'>
                    <div className='col-11 col-sm-8'>
                        <div className='d-flex flex-column'>
                            <button
                                className='btn btn-outline-primary btn-md btn-icon d-lg-none'
                                onClick={() => setOptionsMenuIsVisible(!optionsMenuIsVisible)}
                                data-bs-toggle='collapse'
                                data-bs-target={`#options-row-${item.data.id}`}
                                aria-expanded={optionsMenuIsVisible}
                                aria-controls={`options-row-${item.data.id}`}>
                                {getConfigValue(appState.config?.PlanList?.Options?.Label, appState)?.Value}{' '}
                                <span className='fa-regular fa-ellipsis btn-icon'></span>
                            </button>
                        </div>
                        <button
                            className='btn btn-ghost-secondary btn-sm btn-icon-only d-none d-lg-flex'
                            onClick={() => setOptionsMenuIsVisible(!optionsMenuIsVisible)}
                            data-bs-toggle='collapse'
                            data-bs-target={`#options-row-${item.data.id}`}
                            aria-expanded={optionsMenuIsVisible}
                            aria-controls={`options-row-${item.data.id}`}>
                            <span className='fa-regular fa-ellipsis btn-icon'></span>
                        </button>
                    </div>
                </div>
            </div>

            <PlanListModal
                show={showPlanListModal}
                setShow={setShowPlanListModal}
                prescriberData={prescriberData}
                displayName={displayName}
                showBackButton={false}
            />
        </div>
    );
}

export default BatchPrintRowOptionsMenu;
