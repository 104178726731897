import { useState, useEffect, useContext } from "react";
import useComponentVisible from "../hooks/useComponentVisible";
import { AppContext } from "../util/AppContext";
import { Link } from 'react-router-dom';
import { logOutPost } from '../util/AuthRoutes';
import { getConfigValue } from "../util/getConfigValue";

function Navbar() {
    const [appState, setAppState] = useContext(AppContext);
    const [buttonRef, isActive, setActive] = useComponentVisible(false);
    const [buttonClass, setButtonClass] = useState('');
    const [user, setUser] = useState('');
    const [role, setRole] = useState('');
    const [isProfileActive, setIsProfileActive] = useState(false);
    const [isAdminActive, setIsAdminActive] = useState(false);
    const [isHelpActive, setIsHelpActive] = useState(false);
    const [quickSheetEnabled, setQuickSheetEnabled] = useState((getConfigValue(appState?.config?.QuickSheets?.Enabled, appState)?.Value || 'false') === 'true');
    const professionalPrintingEnabled = getConfigValue(appState?.config?.ProfessionalPrinting?.Enabled, appState)?.Value === 'true' || false;
    const batchPrintEnabled = getConfigValue(appState.config?.BatchPrint?.Enabled, appState)?.Value === 'true' || false;
    const agiLinkEnabled = appState?.hasAgiAccess.toLowerCase() === "true" || false;
    const agiLinkUrl = process.env.REACT_APP_AGI_URL;

    useEffect(() => {
        setQuickSheetEnabled((getConfigValue(appState?.config?.QuickSheets?.Enabled, appState)?.Value || 'false') === 'true');
    }, [appState, appState.configLoaded]);

    useEffect(() => {
        if (isActive) {
            navActivate();
        } else {
            navDeactivate();
        }
    }, [isActive]);

    useEffect(() => {
        if (appState.user && typeof appState.user === 'string') {
            setUser(appState.user);
        }
    }, [appState.user]);

    useEffect(() => {
        if (appState.role && typeof appState.role === 'string') {
            setRole(appState.role);
        }
    }, [appState.role]);

    function setFocus(focus) {
        setActive(false);
        setAppState({
            ...appState,
            ...({
                market: {},
                territory: {}
            }),
            focus: focus,
            prescriber: {},
            products: [],
            currentProduct: "",
            plans: [],
            category: "",
            mainMessage: "",
        });
    }

    function navActivate() {
        document.body.classList.add('nav-active');
        setButtonClass('active');
    }

    function navDeactivate() {
        document.body.classList.remove('nav-active');

        if (buttonClass === 'active') {
            setButtonClass('active inactive');
            setButtonClass(''); //remove class after animation
        }
    }

    async function endImpersonation() {
        setAppState({
            ...appState,
            impersonate: {
                isActive: false,
                firstName: "",
                lastName: "",
                email: "",
                role: "",
            },
        })
        await logOutPost(appState.isAlternateLogin)
    }
    let impersonatedUser = appState.impersonate?.email;
    if (appState.impersonate?.firstName && appState.impersonate?.lastName) {
        impersonatedUser = appState.impersonate?.firstName + " " + appState.impersonate?.lastName;
    }

    return (
        <>
            <header className="header header-sticky alert-bar-active">
                {appState.impersonate.isActive === true && (
                    <div className="alert-bar danger d-flex align-items-center">
                        <div className="container-xl bleed-xs position-relative">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-sm-auto">
                                    <div className="text-white ps-2 ps-sm-0 pt-1 pt-sm-0">
                                        <span className="fas fa-user me-2"></span>{getConfigValue(appState?.config?.NavBar?.ImpersonationActive?.Label, appState)?.Value || null}
                                        <span className="mx-2">|</span>
                                        <span className="fw-lighter">{impersonatedUser}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm d-flex justify-content-sm-end">
                                    <button onClick={endImpersonation} className="btn btn-link text-white type-12 px-0 ps-2 ps-sm-0" id="impersonationExit">{getConfigValue(appState?.config?.NavBar?.EndImpersonation?.Label, appState)?.Value || null}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={buttonRef} className="container-xl bleed-xs">
                    <div className="header-content">
                        <Link to="/" onClick={() => setFocus('')}>
                            <div className="header-logo">
                                <img src={getConfigValue(appState.config?.ClientBranding?.HeaderLogo, appState)?.Value} alt="Precision Value and Health" style={{ width: "70%" }} />
                            </div>
                        </Link>
                        <div className="header-title">
                            <span className="header-title-text">
                                {getConfigValue(appState.config?.ClientBranding?.ProductName, appState)?.Value}
                            </span>
                        </div>
                        <div className="header-utility pe-2 pe-sm-0">
                            <button type="button" onClick={() => setActive(state => !state)} className={`btn btn-outline-secondary btn-icon-only header-nav-button ${buttonClass}`} id="header-nav-button" aria-expanded={isActive}>
                                <span className="visually-hidden">{getConfigValue(appState?.config?.NavBar?.Menu?.Label, appState)?.Value || null}</span>
                                <span className="header-nav-button-icon"></span>
                            </button>
                        </div>
                    </div>
                    <div className="container-xl bleed-xs position-relative">
                        <div className="row justify-content-end">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 position-relative">
                                <nav className="nav-main">
                                    <ul className="nav-main-list">
                                        <li><Link to="/" onClick={() => setActive(false)}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.Home?.Label, appState)?.Value || null}</Link></li>
                                        {batchPrintEnabled &&
                                            <li><Link to="/FocusPage" onClick={() => setFocus('Messages')}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.CreateSalesAid?.Label, appState)?.Value || null}</Link></li>
                                        }
                                        {!batchPrintEnabled &&
                                            <li><Link to="/TerritoryPage" onClick={() => setFocus('HCPs')}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.CreateSalesAid?.Label, appState)?.Value || null}</Link></li>
                                        }
                                        {quickSheetEnabled &&
                                            <li><Link to="/QuickSheetsPage" onClick={() => setFocus('HCPs')}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.DownloadQuickSheet?.Label, appState)?.Value || null}</Link></li>
                                        }
                                        {(agiLinkEnabled && agiLinkUrl) &&
                                            <li><a href={agiLinkUrl} target="_blank" rel="noopener noreferrer"><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.AGI?.Label, appState)?.Value || null}</a></li>
                                        }
                                        {professionalPrintingEnabled &&
                                            <li>
                                                <button type="button" onClick={() => setIsProfileActive(state => !state)} className="nav-main-section-heading" data-bs-toggle="collapse" data-bs-target="#nav-main-section-profile" aria-expanded={isProfileActive} aria-controls="nav-main-section-profile">{getConfigValue(appState?.config?.NavBar?.Menu?.Profile?.Label, appState)?.Value || null} <span className="link-icon fa-regular fa-chevron-down"></span></button>
                                                <ul className={`nav-main-subsection collapse ${isProfileActive ? "show" : "hide"}`} id="nav-main-section-profile">
                                                    <li><Link to="/AddressPage" onClick={() => setActive(false)}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.MyAddresses?.Label, appState)?.Value || null}</Link></li>
                                                </ul>
                                            </li>
                                        }
                                        {appState.role === "User" || appState.impersonate?.role === "User" ?
                                            "" :
                                            <li>
                                                <button type="button" onClick={() => setIsAdminActive(state => !state)} className="nav-main-section-heading" data-bs-toggle="collapse" data-bs-target="#nav-main-section-admin" aria-expanded={isAdminActive} aria-controls="nav-main-section-admin">{getConfigValue(appState?.config?.NavBar?.Menu?.Admin?.Label, appState)?.Value || null} <span className="link-icon fa-regular fa-chevron-down"></span></button>
                                                <ul className={`nav-main-subsection collapse ${isAdminActive ? "show" : "hide"}`} id="nav-main-section-admin">
                                                    <li><Link to="/UserInfoPage" onClick={() => setActive(false)}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.Admin?.Users?.Label, appState)?.Value || null}</Link></li>
                                                    {quickSheetEnabled &&
                                                        <li><Link to="/QuickSheetsAdminPage" onClick={() => setFocus('HCPs')}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.Admin?.QuickSheet?.Label, appState)?.Value || null}</Link></li>
                                                    }
                                                </ul>
                                            </li>
                                        }
                                        <li>
                                            <button type="button" onClick={() => setIsHelpActive(state => !state)} className="nav-main-section-heading" data-bs-toggle="collapse" data-bs-target="#nav-main-section-help" aria-expanded={isHelpActive} aria-controls="nav-main-section-help">{getConfigValue(appState?.config?.NavBar?.Menu?.Help?.Label, appState)?.Value || null} <span className="link-icon fa-regular fa-chevron-down"></span></button>
                                            <ul className={`nav-main-subsection collapse ${isHelpActive ? "show" : "hide"}`} id="nav-main-section-help">
                                                <li><Link to="/HelpAndContact" onClick={() => setActive(false)}><span className="link-icon fa-regular fa-chevron-right"></span>{getConfigValue(appState?.config?.NavBar?.Menu?.HelpAndContact?.Label, appState)?.Value || null}</Link></li>
                                            </ul>
                                        </li>
                                        <li><div className="color-grey-d1 py-3 px-3 type-14 text-truncate">{appState.impersonate.isActive ? appState.impersonate.email : user || "Email not found."}</div></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-border" style={{ backgroundColor: getConfigValue(appState.config?.ClientBranding?.TopBorderColor, appState)?.Value }} />
            </header>
            <div className="header-spacer"></div>
            {appState.impersonate.isActive === true && (
                // Add an extra header-spacer when impersonation is active
                <div className="header-spacer"></div>
            )}
        </>
    )
}

export default Navbar