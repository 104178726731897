import React, { useContext, useState, useEffect, useMemo } from "react";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import DashboardItemRow from '../components/dashboard/DashboardItemRow';
import { formatSellSheetDate } from '../util/prettyDate';
import { useErrorBoundary } from "react-error-boundary";
import CreateDocumentAddressRow from '../components/CreateDocumentAddressRow';
import { getAddresses } from '../API/address';
import { useLocation, useNavigate } from "react-router-dom";
import CreateDocumentAddNewAddress from "../components/CreateDocumentAddNewAddressModal";
import AlertModal from '../components/AlertModal';
import { placePrintOrder, trackDocumentRequest, trackingEventType } from '../API/documentGenerate';
import Button from 'react-bootstrap/Button';


function CreateProfessionalPrintingDocument() {
    const [appState] = useContext(AppContext);
    const [sellSheets, setSellSheets] = useState([]);
    const [headers, setHeaders] = useState([]);
    const systemDateFormat = getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null;
    const { showBoundary } = useErrorBoundary();
    const [feedbackMessage, setFeedbackMessage] = useState();
    const [addresses, setAddresses] = useState([]);
    const user = appState.impersonate.isActive === true ? appState.impersonate.email : appState.user || null;
    const [alert, setAlert] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [canPlaceOrder, setCanPlaceOrder] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState();
    const [showAddressModal, setShowAddressModal] = useState(false);

    const minQuantity = useMemo(() => parseInt(getConfigValue(appState?.config?.ProfessionalPrinting?.Vendor?.MinCopies, appState)?.Value || 0));
    const maxQuantity = useMemo(() => parseInt(getConfigValue(appState?.config?.ProfessionalPrinting?.Vendor?.MaxCopies, appState)?.Value || 0));

    const [selectedQuantity, setSelectedQuantity] = useState(minQuantity);
    const canDecreaseQuantity = selectedQuantity > minQuantity;
    const canIncreaseQuantity = selectedQuantity < maxQuantity;

    const navigate = useNavigate();
    const { state } = useLocation();

    //Get sellSheets
    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}Dashboard/SellSheet/${encodeURIComponent(appState.sellSheetId)}`;
        const fetchDashboard = async () => {
            await fetch(url, {
                method: 'GET',
                credentials: 'include',
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson) {
                        setSellSheets(responseJson.rows.map((sellSheet) => formatSellSheetDate(sellSheet, systemDateFormat)));
                        setHeaders(responseJson.headers);
                    }
                })
                .catch((err) => {
                    showBoundary(err);
                });
        };

        fetchDashboard();
        loadAddresses();
    }, []);

    useEffect(() => {
        validatePrintQuantity(selectedQuantity);
    }, [selectedQuantity]);

    function loadAddresses() {
        getAddresses(user)
            .then(responseJson => {
                if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
                    const sortedAddresses = sortByPrimary([...responseJson]);
                    setAddresses(sortedAddresses);
                    setFeedbackMessage(null);
                    setCanPlaceOrder(false);
                    handleSelectedAddress(sortedAddresses[0]);
                } else {
                    setFeedbackMessage(getConfigValue(appState.config?.ProfessionalPrinting?.AddressList?.NoAddresses?.Message, appState)?.Value || null);
                    setCanPlaceOrder(false);
                }
            })
            .catch(error => {
                setFeedbackMessage(getConfigValue(appState.config?.ProfessionalPrinting?.AddressList?.NoAddresses?.Message, appState)?.Value || null);
                setCanPlaceOrder(false);
            });
    }

    function addNewAddress() {
        // add address to the list of addresses
        loadAddresses();
    }

    function sortByPrimary(addresses) {
        return addresses.sort((a, b) => b.isPrimary - a.isPrimary);
    };

    function handleSelectedAddress(address) {
        setCanPlaceOrder(true);
        setSelectedAddress(address);
    }

    function showAddAddress() {
        setShowAddressModal(true);
    }

    function updatePrintQuantity(value) {
        let newValue = (isNaN(parseInt(selectedQuantity)) ? 0 : parseInt(selectedQuantity)) + value;

        if (isNaN(newValue)) {
            newValue = "";
        }

        setSelectedQuantity(newValue);
        validatePrintQuantity(newValue);
    }

    function handlePlaceOrder() {
        setIsSaving(true);
        placePrintOrder(user, selectedQuantity, appState.sellSheetId, selectedAddress.id)
            .then(() => {
                setIsSaving(false);
                trackDocumentRequest(trackingEventType.SendToPrintVendor, appState.lastGeneratedDocument, appState.sellSheetId);
                navigate('/successfullySubmitPrintingRequest', { state: { address: selectedAddress } });
            })
            .catch(error => {
                setIsSaving(false);
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.ProfessionalPrinting?.SendToVendor?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.ProfessionalPrinting?.SendToVendor?.Failure?.Message, appState)?.Value || null) });
            });
    }

    function handleBackButton() {
        navigate('/GenerateDocumentPage', { state: { pdfURL: state?.pdfURL, fileName: state?.fileName } });
    }

    function handleChange(event) {
        let newValue = isNaN(parseInt(event.target.value)) ? '' : event.target.value;
        setSelectedQuantity(newValue);
    }


    function validatePrintQuantity(value) {
        if (value < minQuantity || value > maxQuantity) {
            setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.ProfessionalPrinting?.InvalidQuantity?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.ProfessionalPrinting?.InvalidQuantity?.Error, appState)?.Value || null) });
        }
        else {
            setAlert();
        }
    }

    useEffect(() => {
        setCanPlaceOrder(selectedAddress && (selectedQuantity >= minQuantity && selectedQuantity <= maxQuantity));
    }, [selectedAddress, selectedQuantity, maxQuantity, minQuantity]);

    return (
        <>
            <div className="title-bar">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-11 col-lg-12 col-xl-10 px-0">
                            <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.ProfessionalPrinting?.Header, appState)?.Value || null}</h1>
                            <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.ProfessionalPrinting?.BreadCrumb?.CreateDocument?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-current">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.ProfessionalPrinting?.BreadCrumb?.PlaceOrder?.Label, appState)?.Value || null}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-lg mt-5">
                <div className="row justify-content-center">
                    <div className="col-11 col-lg-12 col-xl-10">
                        {sellSheets.map((sellSheet) => {
                            return <DashboardItemRow key={sellSheet.data.id} headers={headers} sellSheet={sellSheet} canShowStatus={false} />;
                        })}
                        {alert && (
                            <div className="col-12 col-md-8 col-lg-9">
                                <AlertModal alert={alert} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="row justify-content-center mt-5 mt-lg-6 mb-4 mb-lg-5">
                    <div className="col-12 col-md-11 col-lg-12 col-xl-10">
                        <div className="row justify-content-center justify-content-md-between">
                            <div className="col-11 col-md-3 col-lg-5 px-0 ps-md-0 pe-md-3">
                                <h3 className="type-20 mb-3">{getConfigValue(appState.config?.ProfessionalPrinting?.EnterQuantity?.Label, appState)?.Value || null}</h3>
                                <div className="card pd-card px-5 py-4 py-md-5">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col col-md-12 col-lg-5">
                                            <label className="form-label">{getConfigValue(appState.config?.ProfessionalPrinting?.PrintQuantity, appState)?.Value || null}
                                                <input type="text"
                                                    inputMode="numeric"
                                                    value={selectedQuantity}
                                                    min={minQuantity}
                                                    max={maxQuantity}
                                                    onChange={e => handleChange(e)}
                                                    className="form-control type-42 px-3 py-0 no-spinners"
                                                    id="min-print-quantity" />
                                            </label>
                                            <p className="color-grey-d3 type-14 m-0">{getConfigValue(appState.config?.ProfessionalPrinting?.MaxQuantity, appState)?.Value || null} {maxQuantity}</p>
                                        </div>
                                        <div className="col-4 col-md-12 col-lg-5">
                                            <div className="d-flex justify-content-end mt-md-3 mt-lg-0">
                                                <button className="btn btn-lg btn-outline-primary btn-icon-only me-2" onClick={() => updatePrintQuantity(-1)} disabled={!canDecreaseQuantity}><span className="fa-regular fa-minus btn-icon"></span></button>
                                                <button className="btn btn-lg btn-outline-primary btn-icon-only" onClick={() => updatePrintQuantity(1)} disabled={!canIncreaseQuantity}><span className="fa-regular fa-plus btn-icon"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-11 col-md-9 col-lg-7 mt-6 mt-md-0 pe-md-0">
                                <h3 className="type-20 mb-3">{getConfigValue(appState.config?.ProfessionalPrinting?.AddressList?.Title, appState)?.Value || null}</h3>

                                {!feedbackMessage &&
                                    <ul className="list-group list-group-flush py-3">
                                        {
                                            addresses.map((address) => {
                                                return (
                                                    <CreateDocumentAddressRow
                                                        key={address.id}
                                                        address={address}
                                                        userId={user}
                                                        setAlert={alert}
                                                        loadAddresses={loadAddresses}
                                                        onSelectAddress={handleSelectedAddress}
                                                        isChecked={selectedAddress?.id === address.id || false} />
                                                )
                                            })
                                        }
                                    </ul>
                                }

                                {feedbackMessage &&
                                    <div className="alert alert-warning mb-5" role="alert">
                                        <div className="alert-icon"></div>
                                        <div className="alert-content">
                                            <h4 className="h5 alert-heading">{getConfigValue(appState.config?.ProfessionalPrinting?.AddressList?.NoAddresses?.Title, appState)?.Value || null}</h4>
                                            <p>{feedbackMessage}</p>
                                        </div>
                                    </div>
                                }
                                <div className="col-11 col-md-9 col-lg-7 mt-6 mt-md-0 pe-md-0">
                                    <button className="btn btn-outline-primary btn-lg btn-icon pe-7" onClick={() => showAddAddress()}>{getConfigValue(appState?.config?.ProfessionalPrinting?.NewAddress, appState)?.Value || null} <span className="fa-regular fa-plus btn-icon"></span></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="button-bottom-container-sticky">
                <div className="container-lg">
                    <div className="row justify-content-center py-3">
                        <div className="col-11 col-lg-12 col-xl-10 g-0">
                            <div className="row justify-content-center justify-content-md-end">
                                <div className="col-12 col-sm-6 col-md-7 col-lg-6">
                                    <div className="d-flex flex-column flex-md-row-reverse">
                                        <Button
                                            className={`btn btn-success btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100 ${isSaving ? 'btn-working' : ''}`}
                                            onClick={handlePlaceOrder}
                                            disabled={!canPlaceOrder || isSaving ? true : null}>
                                            {getConfigValue(appState.config?.ProfessionalPrinting?.PlaceOrder, appState)?.Value || null}
                                        </Button>
                                        <button className="btn btn-secondary btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100" onClick={handleBackButton}>{getConfigValue(appState.config?.Common?.Back, appState)?.Value || null}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <CreateDocumentAddNewAddress
                    show={showAddressModal}
                    setShow={setShowAddressModal}
                    onSuccessfulSave={addNewAddress}
                />
        </>
    );
}

export default CreateProfessionalPrintingDocument;