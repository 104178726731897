async function getAllMarkets() {
  const url = `${process.env.REACT_APP_API_URL}Markets`;

  return await fetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const accessArray = responseJson;
      const markets = [];
      if (accessArray) {
        accessArray.forEach((item) => {
          markets.push(item);
        });

        return markets;
      } else {
        throw new Error("No access array found");
      }
    });
}

async function getUserMarkets(user) {
  const escapedUser = encodeURIComponent(user);

  return await fetch(`${process.env.REACT_APP_API_URL}Access/${escapedUser}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const accessArray = responseJson || null;
      const markets = [];

      if (accessArray) {
        // get all markets
        accessArray.forEach((item) => {
          item.markets.forEach((market) => {
            //because the access contains both Application and Dataloader data, there might be duplicates, this merge the records in a single list.
            const existingMarket = markets.find((m) => m.id === market.id);
            if (!existingMarket) {
              market.territories = market.territories.sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              });
              markets.push(market);
            } else {
              market.territories.forEach((territory) => {
                if (
                  !existingMarket.territories.some((t) => t.id === territory.id)
                ) {
                  existingMarket.territories.push(territory);
                }
              });
              existingMarket.territories = existingMarket.territories.sort(
                (a, b) => {
                  return a.name > b.name ? 1 : -1;
                }
              );
            }
          });
        });
        return markets;
      } else {
        throw new Error("No access array found");
      }
    })
    .catch(console.error);
}

async function getBatchPrintTerritories() {
  return await fetch(`${process.env.REACT_APP_API_URL}BatchPrint/Territories`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson || null;
    })
    .catch(console.error);
}

export async function getMarketsForCurrentUser(role, user, setAppState) {
  let markets;

  if (role === "User") {
    markets = await getUserMarkets(user);
  } else {
    markets = await getAllMarkets();
  }

  const batchPrintTerritories = await getBatchPrintTerritories();
  sessionStorage.setItem("marketsForUser", JSON.stringify(markets));
  sessionStorage.setItem(
    "batchPrintTerritories",
    JSON.stringify(batchPrintTerritories)
  );

  setAppState((prevState) => ({
    ...prevState,
    marketsLoaded: true,
  }));
}
