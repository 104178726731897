import { logToServer } from "../API/logging.js";
import GenericErrorMessage from "./GenericErrorMessage.js";
import { ErrorBoundary } from "react-error-boundary";
import { AppContext } from './AppContext';
import { useContext} from 'react';

export default function ReactErrorBoundary(props) {
    const [, setAppState] = useContext(AppContext);
    //clone the fetch function in order to handle 401 errors
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      let [resource, config] = args;
      let response = await originalFetch(resource, config);
      if (response && response.status === 401) {
        setAppState((prev) => ({
            ...prev,
            user: ""
        }));
        window.location.href = `${process.env.REACT_APP_API_RETURN_URL}`;
        return;
      }
      return response;
    };
    
    return (
        <ErrorBoundary
            FallbackComponent={GenericErrorMessage}
            onError={(error, errorInfo) => {
                // log the error
                console.error(error);  
                console.error(errorInfo);
                //try to log to server
                try{
                    logToServer("Error", JSON.stringify({error, errorInfo}));
                }catch(e){
                    console.error("Error logging to server: ", e);
                }
            }}
            onReset={() => {
                sessionStorage.clear();
                window.location.href = `${process.env.REACT_APP_API_RETURN_URL}`;
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}