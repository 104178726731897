function executePrintOptionRestrictions(
  data,
  parentState,
  setSelectableOptions,
  showTooManyOptionsSelectedMessage
) {
  if (!parentState.isSingleOptionSelectionEnabled) {
    return true;
  }

  const selectableOptions = getSelectableOptions(data, parentState);

  if (selectableOptions.totalNumberOfSelectedOptions > 1) {
    showTooManyOptionsSelectedMessage();
    return false;
  }

  // update all options considering whether the config is enabled and other options has been selected or the option type is current selected on another plan.
  setSelectableOptions(selectableOptions);
  return true;
}

function getSelectableOptions(data, parentState) {
  const formularySelected = parentState.isSingleOptionFormularyDrillDownEnabled
    ? isFormularyChecked(data, parentState)
    : 0;
  const restrictionSelected =
    parentState.isSingleOptionRestrictionDetailsEnabled
      ? isRestrictionChecked(data, parentState)
      : 0;
  const specialtyPharmacySelected =
    parentState.isSingleOptionSpecialtyPharmacyEnabled
      ? isSpecialtyPharmacyChecked(data, parentState)
      : 0;
  const isSavingsCardChecked = parentState.isSingleOptionSavingsCardEnabled
    ? data.includeSavingsCard ?? parentState.savingsCardIsChecked
    : 0;

  let result = {
    canSelectFormulary: canSelectFeature(
      parentState.isSingleOptionFormularyDrillDownEnabled,
      formularySelected,
      restrictionSelected + specialtyPharmacySelected + isSavingsCardChecked
    ),
    canSelectRestrictions: canSelectFeature(
      parentState.isSingleOptionRestrictionDetailsEnabled,
      restrictionSelected,
      formularySelected + specialtyPharmacySelected + isSavingsCardChecked
    ),
    canSelectSpecialtyPharmacy: canSelectFeature(
      parentState.isSingleOptionSpecialtyPharmacyEnabled,
      specialtyPharmacySelected,
      formularySelected + restrictionSelected + isSavingsCardChecked
    ),
    canSelectSavingsCard: canSelectFeature(
      parentState.isSingleOptionSavingsCardEnabled,
      isSavingsCardChecked,
      formularySelected + restrictionSelected + specialtyPharmacySelected
    ),
  };

  result.totalNumberOfSelectedOptions =
    formularySelected +
    restrictionSelected +
    specialtyPharmacySelected +
    isSavingsCardChecked;

  return result;
}

function canSelectFeature(
  isFeatureEnabled,
  isFeatureSelected,
  selectedFeatures
) {
  return !isFeatureEnabled || isFeatureSelected ? true : selectedFeatures < 1;
}

function isFormularyChecked(data, parentState) {
  return !data.includeFormularies
    ? parentState.selectedPlans.some(
        (item) => item.planId !== data.planId && item.includeFormularies
      )
    : data.includeFormularies;
}

function isRestrictionChecked(data, parentState) {
  return !data.includeRestrictions
    ? parentState.selectedPlans.some(
        (item) => item.planId !== data.planId && item.includeRestrictions
      )
    : data.includeRestrictions;
}

function isSpecialtyPharmacyChecked(data, parentState) {
  return !data.includeSpecialtyPharmacy
    ? parentState.selectedPlans.some(
        (item) => item.planId !== data.planId && item.includeSpecialtyPharmacy
      )
    : data.includeSpecialtyPharmacy;
}

export default executePrintOptionRestrictions;
