const MessageTypes = {
    ImprovedAccess: 0,
    FavorableAccess: 1,
    Commercial: 2,
    Medicare: 3,
    MedicareDual: 4,
    Medicaid: 5,
    CompetitiveAccess: 6,
    SingleWinPlan: 7
};

export default MessageTypes;