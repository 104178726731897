import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer';
import Navbar from './Navbar';
import { AppContext } from '../util/AppContext';
import Splash from '../pages/splash';

export default function Layout() {
	const [appState] = useContext(AppContext);
	return (
		<>
			{appState.appReady ? (
				<>
					<Navbar />
					<Outlet />
					<Footer />
				</>
			) : (
				<Splash />
			)}
		</>
	);
}
